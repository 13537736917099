<template>
  <div style="height:100%;" class="pb-24">
    <div class="my-24 ml-24 goBack" @click="goBack"><span>×</span>申请发票</div>
    <div
      class="mx-24 mb-24 bg-white pt-24 pb-24"
      style="box-sizing:border-box;overflow:auto;min-height:calc(100% - 74px);"
    >
      <a-steps
        progress-dot
        :current="current"
        class="w700"
        style="margin:0 auto;"
      >
        <a-step v-for="item in steps" :key="item.title" :title="item.title" />
      </a-steps>
      <div class="steps-content" style="width:800px;margin:0 auto;">
        <!-- current:{{ current }} -->
        <!-- 确认开票金额 -->
        <confirmed-amount
          v-if="current === 0"
          @next="nextFn"
          @updateDate="updateDate"
          :initState="formState"
        />
        <!-- 确认信息和地址 -->
        <confirmed-address
          v-if="current === 1"
          @next="nextFn"
          :firstValue="formState"
        />
        <!-- 申请完成 -->
        <div v-if="current === 2" class="text-center">
          <img
            class="mt-100 mb-18"
            style="width:94px;"
            src="http://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/public/over.png"
            alt=""
          />
          <p style="font-size: 16px;font-weight: 500;color: #4d4d4d;">
            申请成功，点击完成返回发票列表
          </p>
          <p
            class="my-16"
            style="font-size: 12px;text-align: CENTER;color: #c1c1c1;line-height: 16px;"
          >
            此次申请的是纸质发票，发票将于15个工作日内完成开具，发票开具完成后3个工作日寄出
          </p>
          <div class="steps-action text-center">
            <a-button v-if="current == 2" type="primary" @click="goBack">
              完成
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { /* message */ Steps } from "ant-design-vue";

//第一步 确认开票金额组件
import confirmedAmount from "./components/confirmedAmount.vue";
//第二步 确认开票金额组件
import confirmedAddress from "./components/confirmedAddress.vue";

// import { useBatchUpload } from "@/hooks/UseBatchUpload";

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    console.log(route.params.amount);
    //确认开票金额表单
    const formRef = ref();
    const formState = ref({
      amount: "",
      invoice_amount: "", //开票金额
      type: "" //SPECIAL: 增值税专用发票 GENERAL: 增值税普通发票
    });
    if (route.params?.amount) {
      console.log("12");
      formState.value.invoice_amount = route.params?.amount / 100;
      formState.value.type = route.params.type;
    }
    /* //申请发票
    const invoiceAccountApply = useInvoiceAccountApplyApi();
    invoiceAccountApply({});
    //查询公司开票信息  需要获取公司id
    console.log(state);
    const companyInvoiceQuery = useCompanyInvoiceQueryApi();
    const companyInvoiceInfo = ref({});
    (async () => {
      const res = await companyInvoiceQuery();
      companyInvoiceInfo.value = res;
    })(); */
    //翻页
    const current = ref(0);
    const next = () => {
      if (current.value === 0) {
        formRef.value
          .validate()
          .then(() => {
            //验证通过
            current.value++;
          })
          .catch(err => console.log(err));
      } else {
        current.value++;
      }
    };
    const prev = () => {
      current.value--;
    };

    //api
    const templateUrl = ref("");

    //validateInfos
    // 省市区选择
    const handleCityCheck = value => {
      formState.value.address = value; //省
      formState.value.province = value[0]; //省
      formState.value.city = value[1]; //市
      formState.value.area = value[2]; //区
    };

    //成功 失败回调
    // const success = () => {
    //   message.success("提交成功！");
    //   ctx.emit("changeStatus", "PENDING");
    //   // setTimeout(() => {
    //   //   ctx.emit("changeStatus", "APPROVED");
    //   // }, 3000);
    // };
    // const error = () => {
    //   message.error("提交失败！");
    // };
    // const resetForm = () => {
    //   formRef.value.resetFields();
    // };
    //返回上一页goBack
    const goBack = () => {
      router.push("/invoice/service-invoice");
    };
    const nextFn = direction => {
      if (direction === false) {
        current.value--;
      } else {
        current.value++;
      }
    };
    //子组件数据同步到父组件  最后的请求使用
    const updateDate = data => {
      Object.keys(formState.value).forEach(item => {
        formState.value[item] = data.value[item];
      });
    };
    const steps = [
      {
        title: "确认开票金额"
      },
      {
        title: "确认信息和地址"
      },
      {
        title: "申请完成"
      }
    ];

    return {
      formState,
      //   formRefRules,
      //   resetForm,
      handleCityCheck,
      templateUrl,
      //   formRef,
      goBack,
      steps,
      next,
      nextFn,
      prev,
      //   labelCol: { span: 6 },
      //   wrapperCol: { span: 18 },
      current,
      updateDate
    };
  },
  components: {
    // aRadio: Radio,
    // aRadioGroup: Radio.Group,
    aSteps: Steps,
    aStep: Steps.Step,
    confirmedAmount,
    confirmedAddress
  }
});
</script>
<style scoped lang="less">
.goBack {
  display: inline-block;
  line-height: 22px;
  cursor: pointer;
  span {
    font-size: 26px;
    margin-right: 16px;
  }
}
//禁用字号color #4d4d4d
:deep(.ant-input[disabled]) {
  color: #4d4d4d;
}
.addressBox li {
  width: 254px;
  height: 118px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  &.active {
    border-color: #1684fb;
    .waitshow {
      display: block;
    }
    .setDefault {
      background: #fff;
      color: #b99675;
      &:hover {
        color: #1684fb;
      }
    }
  }
  &:hover {
    border-color: #1684fb;
  }
  .waitshow {
    display: none;
  }
}
</style>
s
