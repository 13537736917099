<template>
  <!-- 确认信息和地址 -->
  <div>
    <div class="d-flex align-center mt-44 mb-16">
      <h4>开票信息</h4>
      <a style="margin-left:10px" @click="showModal = true">修改开票信息</a>
    </div>
    <div style="margin-top:18px;">
      <a-form
        style="margin:0 auto;background:#F8F9FB;overflow: hidden;padding-top: 10px;"
        :model="companyInvoiceInfo"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-form-item label="发票抬头" name="name">
          {{ companyInvoiceInfo.name }}
        </a-form-item>
        <a-form-item label="纳税人识别号" name="tax_number">
          {{ companyInvoiceInfo.tax_number }}
        </a-form-item>
        <a-form-item label="开户银行名称" name="buyer_bank">
          {{ companyInvoiceInfo.buyer_bank }}
        </a-form-item>
        <a-form-item label="开户银行账号" name="buyer_bank_account">
          {{ companyInvoiceInfo.buyer_bank_account }}
        </a-form-item>
        <a-form-item label="企业注册地址" name="buyer_address">
          {{ companyInvoiceInfo.buyer_address }}
        </a-form-item>
        <a-form-item label="企业联系电话" name="contact_phone">
          {{ companyInvoiceInfo.contact_phone }}
        </a-form-item>
      </a-form>
    </div>
    <h4 class="mt-32 mb-16">邮寄地址</h4>
    <address-list @handlerChange="handlerChange"></address-list>
    <div class="steps-action text-center mt-64">
      <a-button @click="next(false)">上一页</a-button>
      <a-button style="margin-left: 8px" type="primary" @click="goNext"
        >下一页</a-button
      >
    </div>
    <a-modal
      title="完善开票信息"
      :visible="showModal"
      @cancel="showModal = false"
      @ok="handleOk"
    >
      <a-form
        :labelCol="{ span: 6 }"
        :rules="rules"
        ref="formRef"
        :model="changeInfo"
      >
        <a-form-item label="发票抬头">
          <a-input disabled :value="changeInfo.name" />
        </a-form-item>
        <a-form-item label="纳税人识别号">
          <a-input disabled :value="changeInfo.tax_number" />
        </a-form-item>
        <a-form-item label="开户银行名称" name="buyer_bank">
          <a-input v-model:value="changeInfo.buyer_bank" />
        </a-form-item>
        <a-form-item label="开户银行账号" name="buyer_bank_account">
          <a-input v-model:value="changeInfo.buyer_bank_account" />
        </a-form-item>
        <a-form-item label="企业注册地址" name="buyer_address">
          <a-textarea v-model:value="changeInfo.buyer_address" />
          <p class="fz-12" style="color:#FF9800">
            注：详细地址长度需要在5-120个汉字或字符，不能包含表情符号
          </p>
        </a-form-item>
        <a-form-item label="企业注册电话" name="contact_phone">
          <a-input v-model:value="changeInfo.contact_phone" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, isRef, watch } from "vue";
import { state } from "@/store/modules/user";
import {
  // useCompanyInvoiceQueryApi,
  useInvoiceAccountApplyApi,
  useInvoiceAccountUpdateApi
} from "@/apis/service";
import {
  useQueryCompanyDetailApi,
  useUpdateCompanyDetailApi
} from "@/apis/account";
import addressList from "./addressList.vue";
import { message } from "ant-design-vue";
import { useRoute } from "vue-router";
export default defineComponent({
  props: {
    firstValue: {
      require: true
    }
  },
  setup(props, ctx) {
    const route = useRoute();
    const showModal = ref(false);
    const formRef = ref();
    const rules = {
      buyer_bank: [
        { required: true, message: "开户银行名称不能为空", trigger: "blur" }
      ],
      buyer_bank_account: [
        {
          required: true,
          message: "开户银行账号不能为空",
          trigger: "blur"
        }
      ],
      buyer_address: [
        {
          required: true,
          message: "企业详细地址不能为空",
          trigger: "blur"
        },
        {
          min: 5,
          max: 120,
          message: "企业地址长度需在5-120个汉字或字符",
          trigger: "blur"
        }
      ],
      contact_phone: [
        {
          required: true,
          message: "企业联系电话不能为空",
          trigger: "blur"
        }
      ]
    };
    //查询公司开票信息  需要获取公司id
    const queryCompanyDetail = useQueryCompanyDetailApi();
    const companyInvoiceInfo = ref({});
    const changeInfo = ref({});
    (async () => {
      const res = await queryCompanyDetail({
        company_id: state.companyId
      });
      companyInvoiceInfo.value = res;
      changeInfo.value = res;
    })();

    // 保存修改的开票信息
    const updateCompanyDetailApi = useUpdateCompanyDetailApi();
    const handleOk = async () => {
      await formRef.value.validate();
      const res = await updateCompanyDetailApi({
        ...changeInfo.value,
        buyer_phone: changeInfo.value.contact_phone
      });
      if (res) {
        message.success("修改成功");
        companyInvoiceInfo.value = changeInfo.value;
        showModal.value = false;
      }
    };

    //保存地址id
    const addressId = ref({
      company_address_id: ""
    });
    const handlerChange = data => {
      if (isRef(data)) {
        addressId.value.company_address_id = data.value;
      }
      // ctx.emit("updateDate", addressId);
    };

    //下一页
    const next = direction => {
      // ctx.emit("updateDate", addressId);
      ctx.emit("next", direction);
    };

    //申请开票
    const invoiceAccountApply = useInvoiceAccountApplyApi();
    //更新开票
    const invoiceAccountUpdateApi = useInvoiceAccountUpdateApi();

    //验证并跳转到下一页
    const goNext = async () => {
      const { amount, invoice_amount, ...others } = props.firstValue;
      console.log(amount);
      if (route.params?.id) {
        //验证规则
        let data = {
          ...others,
          ...addressId.value,
          id: route.params.id
        };
        data.amount = invoice_amount * 100;
        await invoiceAccountUpdateApi(data);
      } else {
        //验证规则
        let data = {
          ...others,
          ...addressId.value
        };
        data.amount = invoice_amount * 100;
        await invoiceAccountApply(data);
      }
      //下一页
      next();
    };
    //企业地址
    const watchAddress = ref({});
    watch(
      companyInvoiceInfo,
      () => {
        if (
          companyInvoiceInfo.value.province &&
          companyInvoiceInfo.value.city &&
          companyInvoiceInfo.value.area
        ) {
          watchAddress.value = `${companyInvoiceInfo.value.province}/${companyInvoiceInfo.value.city}/${companyInvoiceInfo.value.area}`;
        } else {
          watchAddress.value = "-";
        }
      },
      { immediate: true }
    );

    return {
      formRef,
      companyInvoiceInfo,
      handlerChange,
      addressId,
      next,
      goNext,
      watchAddress,
      showModal,
      changeInfo,
      rules,
      handleOk
    };
  },
  components: { addressList }
});
</script>
