<template>
  <!-- 确认开票金额 -->
  <div v-show="formState.amount" class="mt-80">
    <!-- 表单 -->
    <a-form
      ref="formRef"
      style="width:500px;margin:0 auto;"
      :rules="formRefRules"
      :model="formState"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-item label="可开票金额" name="amount">
        <a-input v-model:value="formState.amount" disabled suffix="元" />
      </a-form-item>
      <p class="tips" v-if="formState.type">
        <span v-if="formState.type === 'SPECIAL'">
          注：增值税专用发票的【可开票金额】为企业对应公户充值且已消费的金额，个人户充值消费后请选择“增值税普通发票
          ”进行开票。
        </span>
        <span v-if="formState.type === 'GENERAL'">
          注：增值税普通发票的【可开票金额】为个人户充值且已消费的金额，可开普票金额+可开专票金额=可开票总金额。
        </span>
      </p>
      <a-form-item
        ref="invoice_amount"
        label="申请开票金额"
        name="invoice_amount"
      >
        <a-input
          v-model:value.number="formState.invoice_amount"
          type="number"
          @input="inputAmount(formState.invoice_amount, formState.amount)"
          suffix="元"
        />
      </a-form-item>
      <a-form-item name="type" style="margin-left:125px;">
        <a-radio-group v-model:value="formState.type">
          <a-radio value="SPECIAL">增值税专用发票</a-radio>
          <a-radio value="GENERAL">增值税普通发票</a-radio>
        </a-radio-group>
        <div v-if="showTypeValidator" style="color:#ff4d4f">
          <!-- ====>{{ !formState.type }}====>{{
            formState.type === "SPECIAL" && improveVisible === true
          }} -->
          <p v-if="!formState.type">开票类型不能为空</p>
          <p v-if="formState.type === 'SPECIAL' && improveVisible">
            如需申请增值税专用发票，请<span
              style="color:#1684FB;cursor:pointer;"
              @click="goService"
              >请完善企业开票信息</span
            >
          </p>
        </div>
      </a-form-item>
    </a-form>
    <!-- 翻页 -->
    <div class="text-center mt-64">
      <a-button
        style="margin-left: 8px"
        type="primary"
        @click="next"
        :disabled="formState.amount < 500"
        >下一页</a-button
      >
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { Radio } from "ant-design-vue";
import {
  useInvoiceAccountTotalApi
  // useCompanyInvoiceQueryApi
  // useCompanyIncoiceInfoUpdateApi
} from "@/apis/service";
import {
  useQueryCompanyDetailApi
  // useUpdateCompanyDetailApi
} from "@/apis/account";

// import { Message } from "ant-design-vue"
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  props: {
    initState: {
      require: true
    }
  },
  setup(props, ctx) {
    //ref 子组件
    const formRef = ref();
    const route = useRoute();

    //确认开票金额表单
    const formState = ref({
      amount: 0,
      invoice_amount: "", //开票金额
      type: "" //SPECIAL: 增值税专用发票 GENERAL: 增值税普通发票
    });
    //初始化表单数据
    Object.keys(formState.value).forEach(item => {
      formState.value[item] = props.initState[item];
    });

    //可开票金额查询
    const invoiceAccountTotal = useInvoiceAccountTotalApi();
    invoiceAccountTotal().then(res => {
      if (route.params?.amount) {
        formState.value.amount =
          (+res?.total + +route.params.amount) / 100 || "";
      } else {
        formState.value.amount = res?.total / 100 || "";
      }
    });

    //验证申请开票金额
    const validateInvoiceAmount = () => {
      //   alert(111);
      if (!formState.value.invoice_amount) {
        return Promise.reject("申请开票金额不能为空");
      } else {
        if (formState.value.amount < 500) {
          return Promise.reject(`申请开票金额不足，无法开票`);
        }
        if (
          formState.value.invoice_amount < 500 ||
          formState.value.invoice_amount > formState.value.amount
        ) {
          return Promise.reject(
            `申请开票金额应大于等于${
              formState.value.amount > 500 ? "500" : formState.value.amount
            }小于等于${formState.value.amount}`
          );
        } else {
          return Promise.resolve();
        }
      }
    };

    //表单验证规则
    const formRefRules = {
      invoice_amount: [
        {
          required: true,
          // message: "申请开票金额不能为空",
          validator: validateInvoiceAmount,
          trigger: "blur"
        }
      ]
    };

    //查询公司开票信息
    // const companyInvoiceQuery = useCompanyInvoiceQueryApi();
    const queryCompanyDetail = useQueryCompanyDetailApi();
    const showTypeValidator = ref(false);
    const improveVisible = ref(false);
    (async () => {
      const res = await queryCompanyDetail();
      //需要验证的字段
      const validatorField = [
        "buyer_name",
        "buyer_tax_number",
        "buyer_bank",
        "buyer_bank_account",
        "buyer_address",
        "buyer_phone"
      ];
      validatorField.forEach(key => {
        if (!res[key]) {
          improveVisible.value = true;
        }
      });
    })();

    // const resetForm = () => {
    //   formRef.value.resetFields();
    // };

    //下一步 翻页
    const next = () => {
      //验证type  showTypeValidator
      const typeValidator = () => {
        if (!formState.value.type) {
          showTypeValidator.value = true;
        } else if (formState.value.type === "SPECIAL") {
          if (improveVisible.value) {
            showTypeValidator.value = true;
          } else {
            showTypeValidator.value = false;
          }
        } else {
          showTypeValidator.value = false;
        }
      };
      typeValidator();
      //数据同步给父元素  并进入下一步
      formRef.value.validate().then(() => {
        if (!showTypeValidator.value) {
          ctx.emit("updateDate", formState);
          ctx.emit("next");
        }
      });
    };

    //跳转到开票信息页面   完善
    const router = useRouter();
    const goService = () => {
      router.push({ path: "/invoice/service-invoice", query: { next: 1 } });
    };

    //inputAmount
    const inputAmount = (value, max) => {
      if (value > max) {
        formState.value.invoice_amount = max;
      }
    };

    return {
      formRef,
      formState,
      formRefRules,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      next,
      showTypeValidator,
      goService,
      inputAmount,
      improveVisible
    };
  },
  components: {
    aRadio: Radio,
    aRadioGroup: Radio.Group
  }
});
</script>
<style lang="less" scoped>
/deep/.ant-form-item {
  label {
    display: block;
    width: 100px;
    text-align: right;
  }
  label.ant-radio-wrapper {
    display: inline-block;
    width: auto;
  }
}
/deep/.ant-form-item label::before {
  content: "";
}
/deep/.ant-form-item label::after {
  content: "";
}
/deep/.ant-form-item input {
  margin-left: 6px;
  border-radius: 5px;
  width: 340px;
}
/deep/.ant-form-item {
  margin-bottom: 24px;
}
.tips {
  font-size: 12px;
  color: #ff9800;
  margin-bottom: 24px;
  width: 100%;
  overflow: hidden;
  span {
    width: 375px;
    display: block;
    float: right;
  }
}
</style>
